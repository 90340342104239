export const getNavigatorInstance = () => {
  if (typeof window !== 'undefined') {
    if (window.navigator || navigator) {
      return window.navigator || navigator
    }
  }

  return false
}

export const getUserAgent = (userAgent: string) => {
  const navigator = getNavigatorInstance()

  if (navigator) {
    return navigator.userAgent
  }

  if (userAgent) {
    return userAgent
  }

  throw new Error(
    'UserAgent must be given while detecting device on the server.'
  )
}

export const match = (userAgent: string = '', regex: RegExp) => {
  return Boolean(getUserAgent(userAgent).match(regex))
}

// Browser
export const useChrome = (userAgent: string) => match(userAgent, /Chrome/i)
export const useDolphin = (userAgent: string) => match(userAgent, /Dolphin/i)
export const useEdge = (userAgent: string) => match(userAgent, /Edge/i)
export const useFirefox = (userAgent: string) =>
  match(userAgent, /Firefox|FxiOS/i)
export const useIE = (userAgent: string) =>
  match(userAgent, /IEMobile|MSIE|Trident/i)
export const useMI = (userAgent: string) => match(userAgent, /MIUI Browser/i)
export const useOpera = (userAgent?: string) => match(userAgent, /OPR|Opera/i)
export const useSafari = (userAgent?: string) =>
  match(userAgent, /^((?!chrome|android).)*safari/i)
export const useYandex = (userAgent: string) => match(userAgent, /Yandex/i)

// Engine
export const useGecko = (userAgent: string) => match(userAgent, /Gecko/i)
export const useGoanna = (userAgent: string) => match(userAgent, /Goanna/i)
export const usePresto = (userAgent: string) => match(userAgent, /Presto/i)
export const useTrident = (userAgent: string) => match(userAgent, /Trident/i)

// OS
export const useMac = (userAgent: string) => match(userAgent, /Macintosh/i)
export const useWindows = (userAgent: string) => match(userAgent, /Windows/i)

// Device
export const useMobile = (userAgent?: string) =>
  match(userAgent, /iPhone|iPad|iPod|Android|Opera Mini|Windows Phone/i)

export const useTablet = (userAgent?: string) =>
  match(
    userAgent && userAgent.toLowerCase(),
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
  )
export const useBrowser = (userAgent: string) => !useMobile(userAgent)
