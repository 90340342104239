export function useCombineRefs<T = HTMLElement>(...refs: any[]) {
  return (intance: T) => {
    refs
      .filter((ref) => ref)
      .forEach((ref) => {
        if (typeof ref === 'function') {
          ref(intance)
        } else {
          ref.current = intance
        }
      })
  }
}
