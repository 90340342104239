import React from 'react'
import { classnames } from 'begonya/functions'

export const ButtonGroup: React.FunctionComponent<any> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames('button-group', className)} {...rest}>
      {children}
    </div>
  )
}
